/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { Action, combineReducers } from 'redux';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import localForage from 'localforage';
import { baggageApi } from '@/slices/baggage/apis/baggageApi';
import { discountReducer } from '@/slices/discount/slices/discountSlice';
import { payMethodReducer } from '@/slices/vapi/slices/payMethodSlice';
import { payPromotionReducer } from '@/slices/vconfiguration/slices/payPromotionSlice';
import { myBookingSurveyReducer } from '@/slices/myBookingSurvey/slices/myBookingSurveySlice';

import { orderApi } from '@/slices/gom/apis/orderApi';
import { payApi } from '@/slices/vgate/apis/payApi';
import { bottomNavigationReducer } from '@/slices/bottomNavigation/slices/bottmNavigationSlices';
import { appInfoDrawerReducer } from '@/slices/appInfoDrawer/slices/appInfoDrawer';
import { myBookingReducer } from '@/slices/myBooking/slices/myBookingSlice';
import { globalReducer } from '@/slices/global/globalSlice';
import { fareRuleApi } from '../slices/fareRule/apis/fareRuleApi';

import { areaReducer } from '../slices/area/slices/areaSlice';
import { baggageReducer } from '../slices/baggage/slices/baggageSlice';
// import { serverReducer } from '../slices/common/serverSlice';
import { contactReducer } from '../slices/contact/slices/contactSlice';
import { flightDataReducer } from '../slices/flightData/slices/flightDataSlice';
import { userApi } from '../slices/user/apis/userApi';
import { userReducer } from '../slices/user/slices/userSlice';
import { passengerReducer } from '../slices/passenger/slices/passengerSlice';
import { areaApi } from '../slices/area/apis/areaApi';
import { invoiceApi } from '../slices/invoice/apis/invoiceApi';
import { vApi } from '../slices/vapi/apis/vapi';
import { vConfiguration } from '@/slices/vconfiguration/apis/vconfiguration';
import { searchFlightReducer } from '../slices/area/slices/searchFlightFormSlice';
import { calendarReducer } from '../slices/calendar/calendarSlice';
import { fareRuleReducer } from '../slices/fareRule/slices/fareRuleSlice';
import { flightDataApi } from '../slices/flightData/apis/flightDataApi';
import { sortingFlightReducer } from '../slices/sortingFlight/sortingFlightSlice';
import { botDialogReducer } from '../slices/botDialog/slices/botDialogSlice';
import { filterFlightReducer } from '../slices/filterFlight/filterFlightSlice';
import { selectedFlightReducer } from '../slices/selectedFlight/slices/selectedFlightSlice';
import { surveyApi } from '../slices/survey/apis/surveyApi';
import { strapiReducer } from '../slices/strapi/slices/strapiSlice';
import { menuReducer } from '@/slices/menu/menuSlices';
import { searchBusReducer } from '@/slices/bus/area/slices/searchBusFormSlice';
import { busDataApi } from '@/slices/bus/busData/apis/busDataApi';
import { busDataReducer } from '@/slices/bus/busData/slices/busDataSlice';
import { busOrderApi } from '@/slices/bus/gom/apis/orderApi';
import { myBusRouteBookingApi } from '@/slices/bus/myBooking/apis/myBookingApi';
import { myBusRouteBookingReducer } from '@/slices/bus/myBooking/slices/myBookingSlice';
import { selectedBusRouteReducer } from '@/slices/bus/selectedBusRoute/slices/selectedBusRouteSlice';
import { tripDetailApi } from '@/slices/bus/tripDetail/apis';
import { profileApi } from '@/slices/bus/profile/apis';
import { tripDetailReducer } from '@/slices/bus/tripDetail/slices';
import { headerTabsReducer } from '@/slices/headerTabs/slices/headerTabsSlice';
import { filterBusRouteReducer } from '@/slices/bus/filterBusRoute/filterBusRouteSlice';
import { sortingBusRouteReducer } from '@/slices/bus/sortingBusRoute/sortingBusRouteSlice';
import { pickDropPointApi } from '@/slices/bus/pickDropPoint/apis';
import { selectedPickDropPointReducer } from '@/slices/bus/pickDropPoint/slices';
import { vexSeoReducer } from '@/slices/vexSEO/slices/vexSeo';

import { gtagSliceReducer } from '@/slices/gtag/slices/gtagSlice';
import { goyoloSeoReducer } from '@/slices/goyoloSeo/goyoloSeoSlice';
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
// import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers({
  user: userReducer,
  // server: serverReducer,
  baggage: baggageReducer,
  passenger: passengerReducer,
  contact: contactReducer,
  flightData: flightDataReducer,
  fareRule: fareRuleReducer,
  area: areaReducer,
  sortingFlight: sortingFlightReducer,
  searchFlightForm: searchFlightReducer,
  searchBusForm: searchBusReducer,
  calendar: calendarReducer,
  filterFlight: filterFlightReducer,
  gtagSlice: gtagSliceReducer,
  botDialog: botDialogReducer,
  selectedFlight: selectedFlightReducer,
  discount: discountReducer,
  payMethod: payMethodReducer,
  payPromotion: payPromotionReducer,
  bottomNavigation: bottomNavigationReducer,
  appInfoDrawer: appInfoDrawerReducer,
  myBooking: myBookingReducer,
  global: globalReducer,
  menu: menuReducer,
  searchBus: searchBusReducer,
  busData: busDataReducer,
  filterBusRoute: filterBusRouteReducer,
  myBusRouteBooking: myBusRouteBookingReducer,
  selectedBusRoute: selectedBusRouteReducer,
  sortingBusRoute: sortingBusRouteReducer,
  busTripDetail: tripDetailReducer,
  headerTabs: headerTabsReducer,
  selectedPickDropPoint: selectedPickDropPointReducer,
  myBookingSurvey: myBookingSurveyReducer,
  vexSeo: vexSeoReducer,
  goyoloSeo: goyoloSeoReducer,
  strapi: strapiReducer,
  [userApi.reducerPath]: userApi.reducer,
  [areaApi.reducerPath]: areaApi.reducer,
  [vApi.reducerPath]: vApi.reducer,
  [vConfiguration.reducerPath]: vConfiguration.reducer,
  [surveyApi.reducerPath]: surveyApi.reducer,
  [flightDataApi.reducerPath]: flightDataApi.reducer,
  [fareRuleApi.reducerPath]: fareRuleApi.reducer,
  [baggageApi.reducerPath]: baggageApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [payApi.reducerPath]: payApi.reducer,
  [busDataApi.reducerPath]: busDataApi.reducer,
  [busOrderApi.reducerPath]: busOrderApi.reducer,
  [myBusRouteBookingApi.reducerPath]: myBusRouteBookingApi.reducer,
  [tripDetailApi.reducerPath]: tripDetailApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [pickDropPointApi.reducerPath]: pickDropPointApi.reducer,
  [invoiceApi.reducerPath]: invoiceApi.reducer,
});

const indexedDBStorage = (dbName: string) => {
  const db = localForage.createInstance({
    name: dbName,
  });
  return {
    db,
    getItem: db.getItem,
    setItem: db.setItem,
    removeItem: db.removeItem,
  };
};

const persistConfig = {
  key: 'root10',
  version: 1,
  // storage,
  storage: indexedDBStorage('goyoloDB'),
  blacklist: ['headerTabs', 'flightData', 'busData', 'strapi', 'goyoloSeo', userApi.reducerPath, areaApi.reducerPath, vApi.reducerPath, vConfiguration.reducerPath, surveyApi.reducerPath, flightDataApi.reducerPath, fareRuleApi.reducerPath, baggageApi.reducerPath, orderApi.reducerPath, payApi.reducerPath, busDataApi.reducerPath, busOrderApi.reducerPath, myBusRouteBookingApi.reducerPath, tripDetailApi.reducerPath, profileApi.reducerPath, pickDropPointApi.reducerPath, invoiceApi.reducerPath],
  // stateReconciler: hardSet as any,
  stateReconciler: autoMergeLevel2 as any,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

let blockREHYDRATE = false;
export const persistMiddlware = (storeApi: any) => (next: (action: Action) => void) => (action: Action) => {
  if (action.type === 'persist/PAUSE') {
    blockREHYDRATE = true;
  }
  if (action.type === 'persist/REHYDRATE' && blockREHYDRATE) {
    delete (action as any).payload;
    blockREHYDRATE = false;
  }
  next(action);
};
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat([
    areaApi.middleware,
    userApi.middleware,
    flightDataApi.middleware,
    busDataApi.middleware,
    fareRuleApi.middleware,
    orderApi.middleware,
    payApi.middleware,
    baggageApi.middleware,
    pickDropPointApi.middleware,
    persistMiddlware,
  ]),
});

// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
//     areaApi.middleware,
//     userApi.middleware,
//     flightDataApi.middleware,
//     fareRuleApi.middleware,
//     orderApi.middleware,
//     baggageApi.middleware,
//   ]),
// });

const bindingMiddlewares = (middlewareOptions: any = {}) => (getDefaultMiddleware: any) => getDefaultMiddleware(middlewareOptions).concat([
  areaApi.middleware,
  userApi.middleware,
  flightDataApi.middleware,
  busDataApi.middleware,
  fareRuleApi.middleware,
  orderApi.middleware,
  payApi.middleware,
  baggageApi.middleware,
  pickDropPointApi.middleware,
  persistMiddlware,
]);

export function makeStore() {
  // const isServer = typeof window === 'undefined';
  // if (isServer) {
  //   return store;
  // }

  const persistedStore = configureStore({
    reducer: persistedReducer,
    middleware: bindingMiddlewares({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    devTools: true,
  });
    // eslint-disable-next-line no-underscore-dangle
  (persistedStore as any).__persistor = persistStore(persistedStore);

  return persistedStore;
}

export type RootStore = ReturnType<typeof makeStore>;
export const storeWrapper = createWrapper<RootStore>(makeStore, { debug: false });

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
